import React, { useCallback, useState } from "react";
import get from "lodash/get";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Section from "../components/section";

import { TextField, Select, Form } from "@shopify/polaris";

import cx from "classnames";
import pageStyles from "./page.module.scss";
import buttonStyles from "../system/button.module.scss";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [help, setHelp] = useState("");

  const handleSubmit = useCallback((_event) => {
    const form = {
      name: name,
      email: email,
      message: message,
      help: help,
    };

    setEmail("");
    setName("");
    setMessage("");
    setHelp("");

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", form }),
    })
      .then(() => alert("Success!"))
      .catch((error) => alert(error));
  }, []);

  const handleNameChange = useCallback((value) => setName(value), []);
  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const handleMessageChange = useCallback((value) => setMessage(value), []);
  const handleHelpChange = useCallback((value) => setHelp(value), []);

  const submitStyle = cx(buttonStyles.button, {
    [buttonStyles.outline]: false,
    [buttonStyles.inverted]: false,
    [buttonStyles.alt]: false,
    [buttonStyles.medium]: true,
  });

  const helpOptions = [
    { label: "I'm interested in booking a carer", value: "request" },
    { label: "I have a question", value: "question" },
    {
      label: "I'd like to know about joining the network",
      value: "joining",
    },
  ];

  return (
    <div className={pageStyles.form}>
      <Form onSubmit={handleSubmit}>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div className={pageStyles.formGroup}>
          <TextField
            label="Name"
            type="text"
            name="name"
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className={pageStyles.formGroup}>
          <TextField
            value={email}
            label="Email"
            name="email"
            type="email"
            onChange={handleEmailChange}
          />
        </div>
        <div className={pageStyles.formGroup}>
          <Select
            label="How can we help?"
            name="help"
            options={helpOptions}
            value={help}
            onChange={handleHelpChange}
          />
        </div>
        <div className={pageStyles.formGroup}>
          <TextField
            multiline={4}
            label="Message"
            type="text"
            name="message"
            value={message}
            onChange={handleMessageChange}
          />
        </div>
        <div className={pageStyles.formSubmission}>
          <button className={submitStyle} type="submit">
            Send
          </button>
        </div>
      </Form>
    </div>
  );
};

const BasicForm = () => (
  <div className={pageStyles.form}>
    <form name="contact" method="POST" data-netlify="true">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className={pageStyles.formGroup}>
        <div>
          <div className={`Polaris-Labelled__LabelWrapper`}>
            <div className={`Polaris-Label`}>
              <label
                id="PolarisTextField1Label"
                for="PolarisTextField1"
                className={`Polaris-Label__Text`}
              >
                Name
              </label>
            </div>
          </div>
          <div className={`Polaris-Connected`}>
            <div
              className={`Polaris-Connected__Item Polaris-Connected__Item--primary`}
            >
              <div className={`Polaris-TextField`}>
                <input
                  name="name"
                  id="PolarisTextField1"
                  className={`Polaris-TextField__Input`}
                  type="text"
                  aria-labelledby="PolarisTextField1Label"
                  aria-invalid="false"
                  aria-multiline="false"
                />
                <div className={`Polaris-TextField__Backdrop`}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={pageStyles.formGroup}>
        <div>
          <div className={`Polaris-Labelled__LabelWrapper`}>
            <div className={`Polaris-Label`}>
              <label
                id="PolarisTextField2Label"
                for="PolarisTextField2"
                className={`Polaris-Label__Text`}
              >
                Email
              </label>
            </div>
          </div>
          <div className={`Polaris-Connected`}>
            <div
              className={`Polaris-Connected__Item Polaris-Connected__Item--primary`}
            >
              <div className={`Polaris-TextField`}>
                <input
                  name="email"
                  id="PolarisTextField2"
                  className={`Polaris-TextField__Input`}
                  type="email"
                  aria-labelledby="PolarisTextField2Label"
                  aria-invalid="false"
                  aria-multiline="false"
                />
                <div className={`Polaris-TextField__Backdrop`}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={pageStyles.formGroup}>
        <div>
          <div className={`Polaris-Labelled__LabelWrapper`}>
            <div className={`Polaris-Label`}>
              <label
                id="PolarisSelect1Label"
                for="PolarisSelect1"
                className={`Polaris-Label__Text`}
              >
                How can we help?
              </label>
            </div>
          </div>
          <div className={`Polaris-Select`}>
            <select
              id="PolarisSelect1"
              name="help"
              // className={`Polaris-Select__Input`}
              aria-invalid="false"
            >
              <option value="request">I'm interested in booking a carer</option>
              <option value="question">I have a question</option>
              <option value="joining">
                I'd like to know about joining the network
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className={pageStyles.formGroup}>
        <div>
          <div className={`Polaris-Labelled__LabelWrapper`}>
            <div className={`Polaris-Label`}>
              <label
                id="PolarisTextField3Label"
                for="PolarisTextField3"
                className={`Polaris-Label__Text`}
              >
                Message
              </label>
            </div>
          </div>
          <div className={`Polaris-Connected`}>
            <div
              className={`Polaris-Connected__Item Polaris-Connected__Item--primary`}
            >
              <div className={`Polaris-TextField Polaris-TextField--multiline`}>
                <textarea
                  name="message"
                  id="PolarisTextField3"
                  className={cx(
                    `Polaris-TextField__Input`,
                    pageStyles.textarea
                  )}
                  type="text"
                  aria-labelledby="PolarisTextField3Label"
                  aria-invalid="false"
                  aria-multiline="true"
                ></textarea>
                <div className={`Polaris-TextField__Backdrop`}></div>
                <div
                  aria-hidden="true"
                  className={`Polaris-TextField__Resizer`}
                >
                  <div className={`Polaris-TextField__DummyInput`}>
                    <br />
                  </div>
                  <div className={`Polaris-TextField__DummyInput`}>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          className={`button-module--button--2_yz4 button-module--medium--3_4uf`}
          type="submit"
        >
          Send
        </button>
      </div>
      <span className={`Polaris-VisuallyHidden`}>
        <button type="submit" aria-hidden="true" tabindex="-1"></button>
      </span>
    </form>
  </div>
);

class Contact extends React.Component {
  render() {
    const siteTitle = "Contact";

    return (
      <Layout location={this.props.location}>
        <SEO title={siteTitle} />{" "}
        <Section>
          <div className={pageStyles.headline}>
            <h2>Get in touch</h2>{" "}
            <p>
              Looking for a carer or have questions about our network, get in
              touch and we'll reply to you swiftly.
            </p>
          </div>
          <BasicForm />
        </Section>
      </Layout>
    );
  }
}

export default Contact;
